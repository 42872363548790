@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// RTL Mode
// @import "./bootstrap.scss";
// @import "./app-rtl.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

#sidebar-menu {
    ul {
        li {
            a {
                i {
                    min-width: 2rem;
                }
            }
        }
    }
}

.noti-icon .badge {
    left: 23px;
}

.dataTables_filter,
.dataTables_paginate {
    float: right;
}

.rdw-editor-main {
    border: 1px solid $gray-300;
    height: 239px;
}

.dz-message {
    text-align: center;
    padding: 30px;
}

// kanban board
.fcYNFc,
.sc-AxmLO.gmtmqV {
    background-color: $card-bg !important;
    margin: 0 20px 0 0 !important;
}

.task-box {
    border: 1px solid $border-color;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.ReactModal__Overlay {
    z-index: 1001 !important;
}

.chat-conversation .right .conversation-list {
    margin-right: 15px;
}

.external-event {
    &:hover {
        cursor: pointer;
    }
}

a:hover {
    cursor: pointer;
}

.rating-container {
    background-color: transparent !important;
}

.input-group-append {
    z-index: 0;
}

.input-color {
    color: $input-color !important;
}

.sketch-picker {
    position: absolute;
    z-index: 1;
}

.rangeslider__fill {
    background-color: $primary !important;
}

.page-content {
    min-height: 800px !important;
}

.card-header {
    background-color: #343a40!important;
    color: #f6f6f6;
}

//
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

